import { ContractApproval } from '~/models/contractApproval';
import { OriginContract, OriginContractForEdit } from '~/models/originContract';
import { object, string, number } from 'yup';
import { ContractCustomValue } from '~/models/contractCustomValue';
import { Property, PropertyForEdit } from '~/models/property';
import { Owner } from './owner';
import {
  Invoice,
  InvoicesForEdit,
  defaultValueInvoicesForEdit,
  InvoiceForEdit
} from './invoice';

export type ContractType =
  | 'sub_lease'
  | 'collection_agency'
  | 'general_management'
  | 'owned'
  | 'other';
export const contractTypeLabels: { [field in ContractType]: string } = {
  sub_lease: 'サブリース',
  collection_agency: '集金代行',
  general_management: '一般管理',
  owned: '自社所有',
  other: 'その他'
};

export type RemittanceDateType = 'last_day' | 'specify_date';

export type Contract = {
  id: number;
  startDate: string | null;
  endDate: string | null;
  contractType: ContractType;
  conditionFixedPeriod: number | null;
  conditionUpdateCycle: number | null;
  revisionPeriod: number | null;
  lowerLimitOfRentRevisionRate: number | null;
  lowerLimitOfRentRevisionRateDuration: number | null;
  remittanceDateType: RemittanceDateType | null;
  remittanceDateSpecify: number | null;
  remittanceStartDate: string | null;
  subLeaseRent: number | null;
  subLeaseParkingRent: number | null;
  numberOfManagedProperties: number | null;
  managementReportingMonth: string;
  contractApproval: ContractApproval | null;
  originContract: OriginContract;
  invoices: Invoice[];
  contractCustomValues: ContractCustomValue[];
  property: Property;
  archived: boolean;
  storeName?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ContractWithOwners = Contract & {
  owners: Owner[];
};

// Form用の型
export type ContractForEdit = Omit<
  ContractWithOwners,
  | 'id'
  | 'archived'
  | 'createdAt'
  | 'updatedAt'
  | 'originContract'
  | 'contractCustomValues'
  | 'property'
  | 'invoices'
> & {
  originContract: OriginContractForEdit;
  customFieldValues: { [field in string]: string | boolean | number };
  property: PropertyForEdit;
  invoices: InvoicesForEdit;
};

export type ContractForEditParams = Omit<ContractForEdit, 'invoices'> & {
  invoices: ReadonlyArray<InvoiceForEdit>;
};

export const defaultContractValue = (): ContractForEdit => ({
  startDate: '',
  endDate: '',
  contractType: 'sub_lease',
  conditionFixedPeriod: null,
  conditionUpdateCycle: null,
  revisionPeriod: null,
  lowerLimitOfRentRevisionRate: null,
  lowerLimitOfRentRevisionRateDuration: null,
  remittanceDateType: null,
  remittanceDateSpecify: null,
  remittanceStartDate: null,
  subLeaseRent: null,
  subLeaseParkingRent: null,
  numberOfManagedProperties: null,
  managementReportingMonth: '',
  contractApproval: null,
  owners: [],
  originContract: {
    contractKey: null,
    startDate: '',
    endDate: '',
    terminationDate: null
  },
  customFieldValues: {},
  property: {
    kensakukunPropertyId: null,
    name: null,
    roomNumber: null,
    parkingSpaceNumber: null,
    propertyType: null
  },
  invoices: defaultValueInvoicesForEdit
});

const commissionValidationSchema = object()
  .shape({
    unitType: string().nullable(),
    amount: string().nullable()
  })
  .test(
    'both_or_neither_validation',
    '金額と単位は両方入力、または未入力にしてください。',
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    (item) =>
      !(item.unitType && !item.amount) && !(!item.unitType && item.amount)
  );

export const validationSchema = object().shape({
  startDate: string().nullable().required('契約開始日を入力してください'),
  endDate: string()
    .nullable()
    .required('契約終了日を入力してください')
    .test(
      'is-after-start-date',
      '契約終了日は契約開始日より後でなければなりません',
      (value, context: DeepReadonly<{ parent: Contract }>) =>
        !context.parent.startDate ||
        !value ||
        new Date(context.parent.startDate) < new Date(value)
    ),
  originContract: object().shape({
    startDate: string().required('原契約開始日を入力してください'),
    endDate: string()
      .nullable()
      .required('原契約終了日を入力してください')
      .test(
        'is-after-start-date',
        '原契約終了日は原契約開始日より後でなければなりません',
        (value, context: DeepReadonly<{ parent: Contract }>) =>
          !context.parent.startDate ||
          !value ||
          new Date(context.parent.startDate) < new Date(value)
      )
  }),
  property: object().shape({
    kensakukunPropertyId: number().nullable().required('指定してください')
  }),
  invoices: object().shape({
    new_contract_commission: commissionValidationSchema,
    renewal_commission: commissionValidationSchema,
    cancellation_commission: commissionValidationSchema
  })
});
