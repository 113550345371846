import dayjs from 'dayjs';

/**
 * 文字列からDateに変換する
 * @param dateStr
 */
export const convertDate = (dateStr: string): Date => dayjs(dateStr).toDate();

/**
 * 文字列からDateに変換する。nullが渡されることも許容する
 *
 * @param date
 */
export const convertDateWithNull = (date: string | null): Date | null => {
  if (date == null || date === '') {
    return null;
  }
  return convertDate(date);
};

/**
 * Dateからクエリ文字列用の文字列に変換する
 * YYYY-MM-DDで返却する
 *
 * @param date
 */
export const convertQueryStringDate = (date: Readonly<Date>): string => {
  const dayjsDate = dayjs(date);
  const format = `YYYY-MM-DD`;
  return dayjsDate.format(format);
};

/**
 * Dateから表示用の文字列に変換する
 * YYYY年MM月DD日(ddd)で返却する
 *
 * @param date
 * @param withDayOfWeek
 */
export const convertDisplayDate = (
  date: DeepReadonly<Date>,
  withDayOfWeek = false
): string => {
  const dayjsDate = dayjs(date);
  const format = `YYYY年MM月DD日${withDayOfWeek ? '(ddd)' : ''}`;
  return dayjsDate.format(format);
};

/**
 * Dateから表示用の文字列に変換する
 * YYYY年MM月DD日 hh:mmで返却する
 *
 * @param date
 */
export const convertDisplayDateTime = (date: Readonly<Date>): string => {
  const dayjsDate = dayjs(date);
  const format = `YYYY年MM月DD日 hh時mm分`;
  return dayjsDate.format(format);
};

/**
 * backendからの値を表示用の日付に変換する
 *
 * @param dateStr
 * @param withDayOfWeek
 */
export const displayDate = (dateStr: string, withDayOfWeek = false): string =>
  convertDisplayDate(convertDate(dateStr), withDayOfWeek);

/**
 * backendからの値を表示用の日付(時分付き)に変換する
 *
 * @param dateStr
 */
export const displayDateTime = (dateStr: string): string =>
  convertDisplayDateTime(convertDate(dateStr));
