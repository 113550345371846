import { OwnerQuery } from '~/models/ownerQuery';
import { ParsedUrlQuery } from 'querystring';

export const convertUrlQueryToOwnerQuery = (
  query: DeepReadonly<ParsedUrlQuery>
): OwnerQuery => {
  const ownerQuery: OwnerQuery = {};
  if (query == null) {
    return ownerQuery;
  }
  return {
    ...(query.page ? { page: Number(query.page) } : {}),
    ...(query.name ? { name: String(query.name) } : {}),
    ...(query.corpName ? { corpName: String(query.corpName) } : {})
  };
};
