import { Staff } from '~/models/staff';
import { OwnerCustomValue } from '~/models/ownerCustomValue';
import { Contract } from '~/models/contract';
import { boolean, object, string } from 'yup';
import { PickedOwnerName } from './contractCollaborationData';

export type Owner = DeepReadonly<{
  id: number;
  ownerKey: string;
  qualifiedInvoiceIssuerRegistrationNumber: string | null;
  lastName: string | null;
  firstName: string | null;
  fullName: string | null;
  lastNameKana: string | null;
  firstNameKana: string | null;
  fullNameKana: string | null;
  corp: boolean;
  corpName: string | null;
  corpNameKana: string | null;
  ceoName: string | null;
  ceoTitle: string | null;
  corpContactName: string | null;
  corpContactNote: string | null;
  sex: 'man' | 'woman' | null;
  birthday: string | null;
  job: string | null;
  destLastName: string | null;
  destFirstName: string | null;
  destLabel: 'sama' | 'onchu' | 'dono' | null;
  tel1: string | null;
  tel2: string | null;
  tel3: string | null;
  email1: string | null;
  email2: string | null;
  email3: string | null;
  address1Zipcode: string | null;
  address1State: string | null;
  address1City: string | null;
  address1Street: string | null;
  address1Other: string | null;
  address1Note: string | null;
  address2Zipcode: string | null;
  address2State: string | null;
  address2City: string | null;
  address2Street: string | null;
  address2Other: string | null;
  address2Note: string | null;
  address3Zipcode: string | null;
  address3State: string | null;
  address3City: string | null;
  address3Street: string | null;
  address3Other: string | null;
  address3Note: string | null;
  shippingAddressIndex: string;
  antisocialCheckDone: boolean;
  antisocialCheckDate: string | null;
  staff: Staff | null;
  note: string | null;
  ownerCustomValues: Readonly<OwnerCustomValue[]>;
  createdAt: string;
  updatedAt: string;
  ownerExtraAttributes: {
    fieldType: string;
    fieldName: string;
    value: string | null;
  }[];
}>;

export type OwnerWithContracts = Owner & {
  contracts: Contract[];
};

export type OwnerForEdit = Omit<Owner, 'id' | 'createdAt' | 'updatedAt'> & {
  ownerCustomFieldValues: { [field in string]: string | boolean | number };
};

const buildAddress = (
  zipcode: string | null,
  state: string | null,
  city: string | null,
  street: string | null,
  other: string | null,
  note: string | null
): Address | null => {
  if (
    (zipcode === null || zipcode === '') &&
    (state === null || state === '') &&
    (city === null || city === '') &&
    (street === null || street === '') &&
    (other === null || other === '') &&
    (note === null || note === '')
  ) {
    return null;
  }
  return {
    zipcode: zipcode ?? '',
    state: state ?? '',
    city: city ?? '',
    street: street ?? '',
    other: other ?? '',
    note: note ?? ''
  };
};

export type Address = {
  zipcode: string;
  state: string;
  city: string;
  street: string;
  other: string;
  note: string;
};
export const addressNumbers = [1, 2, 3] as const;
export const getAddress = (owner: Owner, num: 1 | 2 | 3): Address | null => {
  switch (num) {
    case 1:
      return buildAddress(
        owner.address1Zipcode,
        owner.address1State,
        owner.address1City,
        owner.address1Street,
        owner.address1Other,
        owner.address1Note
      );
    case 2:
      return buildAddress(
        owner.address2Zipcode,
        owner.address2State,
        owner.address2City,
        owner.address2Street,
        owner.address2Other,
        owner.address2Note
      );
    case 3:
      return buildAddress(
        owner.address3Zipcode,
        owner.address3State,
        owner.address3City,
        owner.address3Street,
        owner.address3Other,
        owner.address3Note
      );
    default:
      return null;
  }
};

export const emailNumbers = [1, 2, 3] as const;
export const getEmail = (owner: Owner, num: 1 | 2 | 3): string | null => {
  switch (num) {
    case 1:
      return owner.email1;
    case 2:
      return owner.email2;
    case 3:
      return owner.email3;
    default:
      return null;
  }
};

export const telNumbers = [1, 2, 3] as const;
export const getTel = (owner: Owner, num: 1 | 2 | 3): string | null => {
  switch (num) {
    case 1:
      return owner.tel1;
    case 2:
      return owner.tel2;
    case 3:
      return owner.tel3;
    default:
      return null;
  }
};

const sexLabels: { [field in 'man' | 'woman']: string } = {
  man: '男性',
  woman: '女性'
};
export const displaySexName = (sex: 'man' | 'woman' | null): string =>
  sex ? sexLabels[sex] : '';

const destLabels: { [field in 'sama' | 'onchu' | 'dono']: string } = {
  sama: '様',
  onchu: '御中',
  dono: '殿'
};
export const displayDestName = (
  destLabel: 'sama' | 'onchu' | 'dono' | null
): string => (destLabel ? destLabels[destLabel] : '');

export const displayName = (owner: Owner | PickedOwnerName): string =>
  owner.corp
    ? owner.corpName ?? ''
    : `${owner.lastName ?? ''} ${owner.firstName ?? ''}`;

export const displayNameKana = (owner: Owner | PickedOwnerName): string =>
  owner.corp
    ? owner.corpNameKana ?? ''
    : `${owner.lastNameKana ?? ''} ${owner.firstNameKana ?? ''}`;

export const defaultOwnerValue = (): OwnerForEdit => ({
  ownerKey: '',
  qualifiedInvoiceIssuerRegistrationNumber: '',
  corp: false,
  lastName: '',
  firstName: '',
  fullName: '',
  lastNameKana: '',
  firstNameKana: '',
  fullNameKana: '',
  corpName: '',
  corpNameKana: '',
  ceoName: '',
  ceoTitle: '',
  corpContactName: '',
  corpContactNote: '',
  sex: null,
  birthday: '',
  job: '',
  destLastName: '',
  destFirstName: '',
  destLabel: null,
  address1Zipcode: '',
  address1State: '',
  address1City: '',
  address1Street: '',
  address1Other: '',
  address1Note: '',
  address2Zipcode: '',
  address2State: '',
  address2City: '',
  address2Street: '',
  address2Other: '',
  address2Note: '',
  address3Zipcode: '',
  address3State: '',
  address3City: '',
  address3Street: '',
  address3Other: '',
  address3Note: '',
  shippingAddressIndex: '',
  tel1: '',
  tel2: '',
  tel3: '',
  email1: '',
  email2: '',
  email3: '',
  antisocialCheckDone: false,
  antisocialCheckDate: '',
  note: '',
  ownerCustomFieldValues: {},
  staff: null,
  ownerCustomValues: [],
  ownerExtraAttributes: []
});

export const validationSchema = object().shape({
  corp: boolean().nullable().required('選択してください'),
  firstName: string()
    .nullable()
    .when(['corp', 'fullName'], {
      is: (corp: boolean, fullName: string) => !corp && !fullName,
      then: string().nullable().required('入力してください'),
      otherwise: string().nullable()
    }),
  lastName: string()
    .nullable()
    .when(['corp', 'fullName'], {
      is: (corp: boolean, fullName: string) => !corp && !fullName,
      then: string().nullable().required('入力してください'),
      otherwise: string().nullable()
    }),
  fullName: string()
    .nullable()
    .when('corp', {
      is: false,
      then: string()
        .nullable()
        .when(['firstName', 'lastName'], {
          is: (firstName: string, lastName: string) => !firstName || !lastName,
          then: string()
            .required('姓/名を入力してください')
            .test(
              'contains-space',
              '姓/名は半角スペースで区切って入力してください（例: 山田 太郎）',
              (value: string | undefined) => {
                // 値が空の場合、バリデーションをパスさせる
                if (!value) return false;
                // 正規表現で半角スペースが正しい位置に1つだけ含まれているかをチェックする
                return /^[^\s]+ [^\s]+$/.test(value);
              }
            ),
          otherwise: string().nullable()
        }),
      otherwise: string().nullable()
    }),
  fullNameKana: string()
    .nullable()
    .when('corp', {
      is: false,
      then: string()
        .nullable()
        .when(['firstNameKana', 'lastNameKana'], {
          is: (firstNameKana: string, lastNameKana: string) =>
            !firstNameKana || !lastNameKana,
          then: string().test(
            'contains-space',
            '姓/名カナは半角スペースで区切って入力してください（例: ヤマダ タロウ）',
            (value: string | undefined) => {
              // 値が空の場合、バリデーションをパスさせる
              if (!value) return true;
              // 正規表現で半角スペースが正しい位置に1つだけ含まれているかをチェックする
              return /^[^\s]+ [^\s]+$/.test(value);
            }
          ),
          otherwise: string().nullable()
        }),
      otherwise: string().nullable()
    }),
  corpName: string()
    .nullable()
    .when('corp', {
      is: true,
      then: string().nullable().required('入力してください')
    }),
  antisocialCheckDate: string()
    .nullable()
    .when('antisocialCheckDone', {
      is: true,
      then: string()
        .nullable()
        .required('反社チェック実施済みの場合は実施日を入力してください')
    })
});
