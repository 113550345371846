import React from 'react';
import { CheckBox } from '@itandi/itandi-bb-ui';
import { CustomFieldSetting } from '~/models/customFieldSetting';
import { useFormContext } from 'react-hook-form';
import { ContractForEdit } from '~/models/contract';

type Props = DeepReadonly<{
  customFieldSetting: CustomFieldSetting;
}>;

export const ContractsEditorBlockCustomFieldBoolean: React.FC<Props> = ({
  customFieldSetting
}: Props) => {
  const { setValue, watch } = useFormContext<ContractForEdit>();

  return (
    <CheckBox
      checked={
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        watch(`customFieldValues.${customFieldSetting.fieldKey}`) as boolean
      }
      // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(
          `customFieldValues.${customFieldSetting.fieldKey}`,
          e.target.checked
        );
      }}
    />
  );
};
