import React, { ReactNode } from 'react';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  children?: string | ReactNode;
  width?: number;
}>;

export const TableHeader: React.FC<Props> = ({
  children,
  width = 20
}: Props) => {
  const style = { width: `${width}%` };

  return (
    <th className={Styles.TableHeader} style={style}>
      {children}
    </th>
  );
};
