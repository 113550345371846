import React from 'react';
import { TableHead } from '~/components/common/Table/Head';
import { TableRow } from '~/components/common/Table/Row';
import { TableHeader } from '~/components/common/Table/Header';

export const OwnersListTableHeader: React.FC = () => (
  <TableHead>
    <TableRow>
      <TableHeader width={15}>管理番号</TableHeader>
      <TableHeader width={15}>家主名/会社名</TableHeader>
      <TableHeader width={15}>家主名/会社名(フリガナ)</TableHeader>
      <TableHeader width={15}>担当者</TableHeader>
      <TableHeader width={26}>管理対象</TableHeader>
      <TableHeader width={15}>登録日時</TableHeader>
    </TableRow>
  </TableHead>
);
