import React from 'react';
import { RequiredLabel } from '@itandi/itandi-bb-ui';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  label: string;
  required?: boolean;
  children: React.ReactNode;
  errorMessage?: string;
}>;

export const FormBlockRow: React.FC<Props> = ({
  label,
  required = false,
  children,
  errorMessage
}: Props) => (
  <div className={Styles.FormRow}>
    <div className={Styles.FormLabel}>
      <span className={Styles.FormLabelText}>{label}</span>
      {required && <RequiredLabel className={Styles.RequiredLabel} />}
    </div>
    <div className={Styles.FormContent}>
      {children}
      {errorMessage && (
        <div className={Styles.ErrorMessage}>{errorMessage}</div>
      )}
    </div>
  </div>
);
