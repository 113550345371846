import React from 'react';
import { OwnersSearchArea } from '~/components/specifics/owners/components/SearchArea';
import { OwnersList } from '~/components/specifics/owners/components/List';
import { Button } from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { PATH } from '~/pages/owners/new';
import { LayoutsItandiSessionLayout } from '~/components/common/Layout/ItandiSessionLayout';
import { LayoutsContainer } from '~/components/common/Layout/Container/index';
import Styles from './index.module.scss';

export const Page: React.FC = () => {
  const router = useRouter();
  return (
    <LayoutsItandiSessionLayout>
      <LayoutsContainer title="家主一覧">
        <Button
          className={Styles.CreateButton}
          height="small"
          onClick={(): void => {
            router.push(PATH).then();
          }}
          variant="primary"
        >
          新規登録
        </Button>
        <OwnersSearchArea />
        <OwnersList />
      </LayoutsContainer>
    </LayoutsItandiSessionLayout>
  );
};
