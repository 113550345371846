import React from 'react';
import { RiMoreFill } from 'react-icons/ri';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import {
  InputText,
  InputSelect,
  Input,
  Modal,
  CheckBox
} from '@itandi/itandi-bb-ui';
import { FormBlock } from '~/components/common/Form/Block/index';
import { FormBlockRow } from '~/components/common/Form/Block/Row/index';
import { useStaffs } from '~/hooks/api/useStaffs';
import { StaffWithRoleName } from '~/models/staff';
import Styles from './index.module.scss';

export const OwnersEditorBlockOther: React.FC = () => {
  const { setValue, watch, register, formState } =
    useFormContext<OwnerForEdit>();
  const { errors } = formState;
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const [selectedStaff, setSelectedStaff] =
    React.useState<StaffWithRoleName | null>(null);
  const [name, setName] = React.useState<string>('');
  const { data: staffs } = useStaffs().useIndex({ name });
  const handleSelectStaff = (staff: StaffWithRoleName): void => {
    setValue('staff', {
      id: staff.id,
      name: staff.name,
      featureFlag: staff.featureFlag
    });
    handleClose();
  };
  const handleCancelSelectStaff = (): void => {
    setValue('staff', null);
    setSelectedStaff(null);
  };

  return (
    <FormBlock caption="その他情報" captionIcon={<RiMoreFill />}>
      <FormBlockRow errorMessage={errors.corp?.message} label="担当者">
        <Modal
          handleClose={handleClose}
          handleSubmit={(): void =>
            // !selectedStaffがtrueになるまではsubmitできないので、必ずStaffWithRoleNameが引数に入る。
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            handleSelectStaff(selectedStaff as StaffWithRoleName)
          }
          isOpen={isOpen}
          isSubmitting={!selectedStaff}
          submitLabel="選択"
          title="担当者を選択"
        >
          <Input
            // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setName(e.target.value)
            }
            placeholder="例） 担当太郎"
            value={name}
          />
          {staffs !== null && (
            <ul className={Styles.StaffList}>
              {staffs.objects.map((staff: StaffWithRoleName) => (
                <li key={staff.id} className={Styles.CheckItem}>
                  <CheckBox
                    checked={staff.id === selectedStaff?.id}
                    onChange={(): void => {
                      setSelectedStaff(staff);
                    }}
                  />
                  <span>{staff.name}</span>
                </li>
              ))}
            </ul>
          )}
        </Modal>
        {watch('staff') ? (
          <div className={Styles.Selected}>
            <span>{watch('staff')?.name}</span>
            <button
              className={Styles.DeleteLink}
              onClick={handleCancelSelectStaff}
              type="button"
            >
              解除
            </button>
          </div>
        ) : (
          <button
            className={Styles.SelectButton}
            onClick={handleOpen}
            type="button"
          >
            担当者を選択
          </button>
        )}
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.destLastName?.message}
        label="送金用氏名 姓"
      >
        <Input
          isInvalid={errors.destLastName != null}
          placeholder="例）家主"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('destLastName')}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.destFirstName?.message}
        label="送金用氏名 名"
      >
        <Input
          isInvalid={errors.destFirstName != null}
          placeholder="例）太郎"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('destFirstName')}
        />
      </FormBlockRow>

      <FormBlockRow
        errorMessage={errors.destLabel?.message}
        label="送金用氏名の敬称"
      >
        <InputSelect
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('destLabel')}
        >
          <option value="">選択なし</option>
          <option value="sama">様</option>
          <option value="onchu">御中</option>
          <option value="dono">殿</option>
        </InputSelect>
      </FormBlockRow>

      <FormBlockRow errorMessage={errors.note?.message} label="備考">
        <InputText
          isInvalid={errors.note != null}
          placeholder="例）その他特記事項など"
          rows={5}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('note')}
        />
      </FormBlockRow>
    </FormBlock>
  );
};
