import React, { ReactNode } from 'react';
import {
  displayName,
  displayNameKana,
  OwnerWithContracts
} from '~/models/owner';
import Link from 'next/link';
import { PATH as OWNER_PATH } from '~/pages/owners/[id]';
import { PATH as CONTRACT_PATH } from '~/pages/contracts/[id]';
import { displayDateTime } from '~/utils/dateUtils';
import { Contract } from '~/models/contract';
import { TableRow } from '~/components/common/Table/Row';
import { TableData } from '~/components/common/Table/Data';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  owner: OwnerWithContracts;
}>;

export const OwnersListTableBodyRow: React.FC<Props> = ({ owner }: Props) => (
  <TableRow isInTableBody>
    <TableData>{owner.ownerKey}</TableData>
    <TableData>
      <Link href={OWNER_PATH(owner.id)}>
        <a>{displayName(owner)}</a>
      </Link>
    </TableData>
    <TableData>
      <span>{displayNameKana(owner)}</span>
    </TableData>
    <TableData>
      {owner.staff ? (
        <span>{owner.staff.name}</span>
      ) : (
        <span className={Styles.Empty}>未登録</span>
      )}
    </TableData>
    <TableData isVertical width={26}>
      {owner.contracts.length === 0 && (
        <span className={Styles.Empty}>未登録</span>
      )}
      {owner.contracts.map(
        (contract: DeepReadonly<Contract>): ReactNode => (
          <div key={contract.id}>
            <Link href={CONTRACT_PATH(contract.id)}>
              <a>
                {contract.property ? contract.property.displayName : '未登録'}
              </a>
            </Link>
          </div>
        )
      )}
    </TableData>
    <TableData>{displayDateTime(owner.createdAt)}</TableData>
  </TableRow>
);
