import React from 'react';
import { getItemsCountRange } from '~/utils/pagination';
import Styles from './index.module.scss';

type Props = DeepReadonly<{
  count: number;
  currentPage: number;
  itemsPerPage: number;
}>;

export const TableDisplayCount: React.FC<Props> = ({
  count,
  currentPage,
  itemsPerPage
}: Props) => {
  const itemsCountRange = getItemsCountRange(currentPage, count, itemsPerPage);

  return (
    <div className={Styles.Container}>
      <span className={Styles.TotalCount}>{count}件</span>
      {itemsCountRange}を表示中
    </div>
  );
};
