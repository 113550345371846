import React from 'react';
import { useRouter } from 'next/router';
import { useOwners } from '~/hooks/api/useOwners';
import { displaySexName, Owner } from '~/models/owner';
import { getContentOrEmptyLabel } from '~/components/specifics/owners/[id]/components/Contents/Basic';
import { ListItem } from '~/components/common/List/Item';
import { displayDate } from '~/utils/dateUtils';
import Styles from '../index.module.scss';

const displayKanaName = (owner: Owner): string => {
  const name = `${owner.lastNameKana ?? ''} ${owner.firstNameKana}`;
  return name.length === 1 ? '' : name;
};

export const OwnersContentsBasicIndividual: React.FC = () => {
  const { id = null } = useRouter().query;
  const { data: owner } = useOwners().useShow(id === null ? null : Number(id));
  if (owner === null) {
    return null;
  }
  return (
    <>
      <ListItem
        content={
          <div className={Styles.NameWrapper}>
            <div>
              {owner.lastName} {owner.firstName}
            </div>
            {displayKanaName(owner) !== '' && (
              <div>{displayKanaName(owner)}</div>
            )}
          </div>
        }
        title="氏名"
      />
      <ListItem content={getContentOrEmptyLabel(owner.job)} title="職業" />
      <ListItem
        content={getContentOrEmptyLabel(displaySexName(owner.sex))}
        title="性別"
      />
      <ListItem
        content={getContentOrEmptyLabel(
          owner.birthday ? displayDate(owner.birthday) : null
        )}
        title="生年月日"
      />
    </>
  );
};
