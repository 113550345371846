import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { Input, Button } from '@itandi/itandi-bb-ui';
import { convertUrlQueryToOwnerQuery } from '~/utils/owners/ownerUrlQueryConverter';
import Styles from './index.module.scss';

export const OwnersSearchArea: React.FC = () => {
  const router = useRouter();
  const [query, setQuery] = useState<DeepReadonly<ParsedUrlQuery>>(
    router.query
  );

  const handleChange = (
    key: string,
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.value) {
      setQuery({ ...query, [key]: e.target.value });
    } else {
      const { [key]: _, ...newQuery } = query;
      setQuery(newQuery);
    }
  };

  const handleSubmit = (
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    e: React.FormEvent<HTMLFormElement>
  ): void => {
    e.preventDefault();
    router.push({ query: convertUrlQueryToOwnerQuery(query) });
  };

  return (
    <form className={Styles.SearchArea} onSubmit={handleSubmit}>
      <div>
        <div> 家主名(フリガナでも検索可）</div>
        <Input
          className={Styles.SearchInput}
          // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChange('name', e)
          }
          placeholder="氏名で検索"
          value={query.name || ''}
        />
      </div>

      <div>
        <div>会社名(フリガナでも検索可）</div>
        <Input
          className={Styles.SearchInput}
          // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            handleChange('corpName', e)
          }
          placeholder="会社名で検索"
          value={query.corpName || ''}
        />
      </div>

      <div className={Styles.ButtonWrapper}>
        <Button height="default" type="submit" variant="primary">
          検索
        </Button>
      </div>
    </form>
  );
};
