import React from 'react';
import { CheckBox } from '@itandi/itandi-bb-ui';
import { OwnerCustomFieldSetting } from '~/models/ownerCustomFieldSetting';
import { useFormContext } from 'react-hook-form';
import { OwnerForEdit } from '~/models/owner';

type Props = DeepReadonly<{
  ownerCustomFieldSetting: OwnerCustomFieldSetting;
}>;

export const OwnersEditorBlockCustomFieldBoolean: React.FC<Props> = ({
  ownerCustomFieldSetting
}: Props) => {
  const { setValue, watch } = useFormContext<OwnerForEdit>();

  return (
    <CheckBox
      checked={
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        watch(
          `ownerCustomFieldValues.${ownerCustomFieldSetting.fieldKey}`
        ) as boolean
      }
      // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(
          `ownerCustomFieldValues.${ownerCustomFieldSetting.fieldKey}`,
          e.target.checked
        );
      }}
    />
  );
};
